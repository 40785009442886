import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import GoogleMapReact from 'google-map-react'
import { decodeEntities } from '../utils/htmlParse'
import createMapStyles from '../utils/map-styles'
import './OfficeLocations.scss'

// Google maps native options override
// https://developers.google.com/maps/documentation/javascript/reference/map#MapOptions
const createMapOptions = maps => {
  return {
    disableDefaultUI: true,
    scrollwheel: false,
    styles: createMapStyles(),
  }
}

export const OfficeLocations = () => {
  const {
    wordpressAcfOptions: { options },
  } = useStaticQuery(graphql`
    query {
      wordpressAcfOptions {
        options {
          googleMapsApiKey
          locations {
            locationName
            googleMap {
              lat
              lng
            }
            addressDisplay
            email
            phone
          }
        }
      }
    }
  `)
  const { googleMapsApiKey = null } = options

  // If there are no locations to display return null
  if (
    typeof options.locations === 'undefined' ||
    options.locations.length === 0
  ) {
    return null
  }

  return (
    <section className="office-locations">
      <div className="wrapper">
        {options.locations &&
          options.locations.map((loc, index) => {
            const {
              locationName,
              googleMap,
              addressDisplay,
              email,
              phone,
            } = loc
            return (
              <div className="office-location" key={index}>
                <div className="office-location-details">
                  <h2 className="office-location-name">
                    {decodeEntities(locationName)}
                  </h2>
                  {addressDisplay && (
                    <span className="address">
                      {decodeEntities(addressDisplay)}
                    </span>
                  )}
                  {phone && (
                    <a className="phone" href={`tel:${phone}`}>
                      {decodeEntities(phone)}
                    </a>
                  )}
                  {email && (
                    <a className="email" href={`mailto:${email}`}>
                      {decodeEntities(email)}
                    </a>
                  )}
                </div>
                <div className="map">
                  {/* <GoogleMapReact
                  bootstrapURLKeys={{ key: googleMapsApiKey }}
                  defaultCenter={{
                    lat: parseFloat(googleMap.lat),
                    lng: parseFloat(googleMap.lng)
                  }}
                  defaultZoom={15}
                  options={createMapOptions}
                >
                  <div
                    className="map-marker"
                    lat={googleMap.lat}
                    lng={googleMap.lng}
                  />
                </GoogleMapReact> */}
                </div>
              </div>
            )
          })}
      </div>
    </section>
  )
}
